import { O as OwnerForm } from "./index17.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          staticClass: "create-owner-modal",
          attrs: {
            "title": "Add Owner",
            "after-close": _vm.afterModalClose,
            "width": "680px"
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                key: "cancel",
                on: {
                  "click": _vm.onCancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                key: "submit",
                attrs: {
                  "type": "primary",
                  "loading": _vm.isLoading
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.onSave);
                  }
                }
              }, [_vm._v("Add")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v;
            },
            expression: "visible"
          }
        }, [_c("div", {
          staticClass: "create-owner-modal__content"
        }, [_c("owner-form", {
          attrs: {
            "owner-data": _vm.owner
          }
        })], 1)])];
      }
    }])
  });
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreateOwner",
  inject: ["resourceProps"],
  components: {
    OwnerForm
  },
  data() {
    const [ownerProps] = this.resourceProps;
    const owner = {
      id: void 0,
      lanId: "",
      jobTitle: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      isInactive: false
    };
    return {
      apiUrl: apiUrl$1,
      ownerProps,
      isLoading: false,
      visible: true,
      owner
    };
  },
  created() {
    this.ownerProps.crud.initEntity();
  },
  methods: {
    onSave() {
      this.addNew();
    },
    async addNew() {
      try {
        this.isLoading = true;
        await this.ownerProps.crud.submitEntity("create");
        const pageSize = this.ownerProps.crud.getPagination().pageSize;
        this.ownerProps.crud.setPagination({ page: 1, pageSize });
        this.ownerProps.crud.fetchList();
        this.visible = false;
      } finally {
        this.isLoading = false;
      }
    },
    onCancel() {
      this.visible = false;
    },
    afterModalClose() {
      this.ownerProps.crud.clearEntity("createForm");
      this.$router.push(this.ownerProps.redirectRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateOwner = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "create-owner"
  }, [_c("resource", {
    attrs: {
      "name": ["export-order.manage-owners"],
      "api-url": _vm.apiUrl,
      "redirect-route": ["/export-orders/owner"]
    }
  }, [_c("create-owner")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CreateOwnerIndex",
  components: {
    CreateOwner
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
