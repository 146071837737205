import _ from "lodash";
import { FILTERS_STARTS_WITH } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "land-id-input"
  }, [_vm.formItem ? _c("validation-provider", {
    attrs: {
      "name": _vm.label,
      "rules": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(slotProps) {
        return [_c("a-form-item", {
          attrs: {
            "label": _vm.label,
            "has-feedback": "",
            "help": slotProps.errors[0],
            "validate-status": _vm.resolveState(slotProps)
          }
        }, [_c("a-auto-complete", {
          staticClass: "w-100",
          attrs: {
            "option-label-prop": "title",
            "placeholder": _vm.placeholder,
            "disabled": _vm.isDisabled
          },
          on: {
            "change": _vm.onChange,
            "select": _vm.onSelect,
            "search": _vm.onSearch
          },
          scopedSlots: _vm._u([{
            key: "dataSource",
            fn: function() {
              return [_vm.dataSource.length > 0 ? _c("a-select-option", {
                key: "label"
              }, [_c("a-row", [_c("a-col", {
                attrs: {
                  "xs": 12
                }
              }, [_vm._v("Account Name")]), _c("a-col", {
                attrs: {
                  "xs": 12
                }
              }, [_vm._v("Email")])], 1)], 1) : _vm._e(), _vm._l(_vm.dataSource, function(option) {
                return _c("a-select-option", {
                  key: option.samAccountName,
                  attrs: {
                    "value": option.samAccountName,
                    "title": option.samAccountName
                  }
                }, [_c("a-row", [_c("a-col", {
                  attrs: {
                    "xs": 12
                  }
                }, [_vm._v(_vm._s(option.samAccountName))]), _c("a-col", {
                  attrs: {
                    "xs": 12
                  }
                }, [_vm._v(_vm._s(option.email))])], 1)], 1);
              })];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.inputValue,
            callback: function($$v) {
              _vm.inputValue = $$v;
            },
            expression: "inputValue"
          }
        })], 1)];
      }
    }], null, false, 1963513659)
  }) : [_vm.label ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c("a-auto-complete", {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.isDisabled,
      "placeholder": _vm.placeholder
    },
    on: {
      "change": _vm.onChange,
      "select": _vm.onSelect,
      "search": _vm.onSearch
    },
    scopedSlots: _vm._u([{
      key: "dataSource",
      fn: function() {
        return [_vm.dataSource.length > 0 ? _c("a-select-option", {
          key: "label"
        }, [_c("a-row", [_c("a-col", {
          attrs: {
            "xs": 12
          }
        }, [_vm._v("Account Name")]), _c("a-col", {
          attrs: {
            "xs": 12
          }
        }, [_vm._v("Email")])], 1)], 1) : _vm._e(), _vm._l(_vm.dataSource, function(option) {
          return _c("a-select-option", {
            key: option.samAccountName,
            attrs: {
              "value": option.samAccountName,
              "title": option.samAccountName
            }
          }, [_c("a-row", [_c("a-col", {
            attrs: {
              "xs": 12
            }
          }, [_vm._v(_vm._s(option.samAccountName))]), _c("a-col", {
            attrs: {
              "xs": 12
            }
          }, [_vm._v(_vm._s(option.email))])], 1)], 1);
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.inputValue,
      callback: function($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  })]], 2);
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "LanIdInput",
  props: {
    filterType: {
      type: String,
      default: FILTERS_STARTS_WITH
    },
    formItem: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    searchBy: {
      type: String,
      default: ""
    },
    value: {
      type: [Number, String, Array],
      default: void 0
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "Type to start searching"
    },
    maxLength: {
      type: Number,
      default: void 0
    }
  },
  data() {
    return {
      dataSource: [],
      inputValue: this.value,
      isComplete: false,
      page: 1
    };
  },
  computed: {
    query() {
      return {
        page: this.page
      };
    }
  },
  watch: {
    value(newVal) {
      this.inputValue = newVal;
    }
  },
  methods: {
    async fetchList(keyword = "") {
      const payload = {
        params: {
          keyword: keyword.trim()
        }
      };
      const res = await this.axios.get(`${apiUrl$1}/identities/ad/users`, payload);
      this.dataSource = res.data;
    },
    resolveState({ errors }) {
      if (errors[0])
        return "error";
      return "";
    },
    handleEmitValue(value) {
      const user = this.dataSource.filter((user2) => user2.samAccountName === value)[0];
      if (!user)
        return;
      this.$emit("selected", user);
    },
    onChange: _.debounce(function(value) {
      this.$emit("changed", value);
    }, 1e3),
    onSearch(keyword) {
      if (!keyword || keyword.trim().length === 0) {
        this.dataSource = [];
        return;
      }
      this.page = 1;
      this.fetchList(keyword, this.page);
    },
    onSelect(value) {
      this.handleEmitValue(value);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var LanIdInput = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "create-owner__form"
  }, [_c("a-row", {
    attrs: {
      "gutter": 24,
      "type": "flex"
    }
  }, [_c("a-col", {
    attrs: {
      "xs": 12
    }
  }, [_c("lan-id-input", {
    attrs: {
      "label": "Username/LAN ID",
      "rules": "required",
      "is-disabled": _vm.isEdit,
      "form-item": "",
      "value": _vm.entity.lanId,
      "max-length": 255,
      "search-by": "samAccountName"
    },
    on: {
      "selected": _vm.onUserChange,
      "changed": function($event) {
        return _vm.storeValue("lanId", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12
    }
  }, [_c("text-input", {
    attrs: {
      "value": _vm.entity.jobTitle,
      "label": "Job Title",
      "rules": "required",
      "form-item": ""
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("jobTitle", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12
    }
  }, [_c("text-input", {
    staticClass: "mb-3",
    attrs: {
      "value": _vm.entity.firstName,
      "label": "First Name",
      "form-item": "",
      "rules": "required"
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("firstName", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12
    }
  }, [_c("text-input", {
    staticClass: "mb-3",
    attrs: {
      "value": _vm.entity.lastName,
      "label": "Last Name",
      "form-item": "",
      "rules": "required"
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("lastName", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 24
    }
  }, [_c("text-input", {
    staticClass: "mb-3",
    attrs: {
      "value": _vm.entity.phoneNumber,
      "label": "Phone",
      "form-item": ""
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("phoneNumber", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 24
    }
  }, [_c("text-input", {
    staticClass: "mb-3",
    attrs: {
      "value": _vm.entity.email,
      "label": "Email",
      "form-item": "",
      "rules": {
        required: true,
        email: true
      }
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("email", $event);
      }
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "OwnerForm",
  components: {
    LanIdInput
  },
  inject: ["resourceProps"],
  props: {
    isSync: {
      type: Boolean,
      default: false
    },
    ownerData: {
      type: Object,
      default: null
    }
  },
  data() {
    const [ownerProps, userProps] = this.resourceProps;
    const lanIdRules = {
      required: true,
      regexCustom: {
        regEx: /^[a-zA-Z0-9-._@+]*$/,
        message: "should not contain spaces or special characters (Except - _ . @ +)"
      }
    };
    const formType = this.$route.params.id ? "editForm" : "createForm";
    const isEdit = +this.$route.params.id >= 0;
    const entity = this.ownerData;
    return {
      ownerProps,
      userProps,
      lanIdRules,
      entity,
      dataSource: [],
      userTimeout: null,
      formType,
      isEdit,
      syncing: false
    };
  },
  watch: {
    ownerData(newVal) {
      this.entity = { ...this.entity, ...newVal };
      this.storeValues(this.entity);
    },
    async isSync(newVal) {
      if (!newVal)
        return;
      this.syncFromAD();
    }
  },
  mounted() {
    this.storeValues(this.entity);
  },
  methods: {
    async syncFromAD() {
      const { data } = await this.fetchUserByLanId(this.entity.lanId);
      this.entity = this.mapData(data);
      this.storeValues(this.entity);
      this.$emit("update:isSync", false);
    },
    onUserChange(user) {
      this.entity = this.mapData(user);
      this.storeValues(this.entity);
    },
    mapData(user) {
      return {
        lanId: user.samAccountName,
        jobTitle: user.title,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber
      };
    },
    fetchUserByLanId(lanId = "") {
      return this.axios.get(`${apiUrl}/identities/ad/users/${lanId}`);
    },
    fetchUsers(keyword = "") {
      const payload = {
        params: {
          keyword: keyword.trim()
        }
      };
      return this.axios.get(`${apiUrl}/identities/ad/users`, payload);
    },
    storeValue(resourceKey, value) {
      this.entity = { ...this.entity, [resourceKey]: value };
      this.ownerProps.crud.updateEntity(this.formType, {
        resourceKey,
        value
      });
    },
    storeValues(entity) {
      const fields = Object.keys(entity);
      fields.forEach((key) => this.storeValue(key, entity[key]));
    },
    findAndFill(username) {
      const user = this.dataSource.filter((user2) => user2.samAccountName === username)[0];
      if (user) {
        this.entity = this.mapData(user);
        this.storeValues(this.entity);
      }
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var OwnerForm = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { OwnerForm as O };
